<template>
  <div class="app-value-outlet" :class="{ editable: editable }" role="alert">
    <div class="value-container">
      <div v-if="label" class="label">{{ label }}</div>
      <div class="value" style="line-height: normal; margin-bottom: 0">
        {{ value }}
      </div>
    </div>
    <button
      v-if="editable"
      type="button"
      class="btn btn-link btn-edit"
      @click="$emit('edit')"
    >
      {{ editButtonText }}
    </button>
  </div>
</template>

<script>
export default {
  emits: ["edit"],
  props: {
    label: String,
    value: String,
    editable: Boolean,
    editButtonText: {
      type: String,
      default: "Değiştir"
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/variables";
@import "../scss/mixins";

.app-value-outlet {
  font-size: 15px;
  color: #000;
  border: solid 2px $input-border-color;
  background: #f4f8f9;
  padding: 15px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: $border-radius;

  &.editable {
    padding-right: 15px;
  }

  .label {
    font-size: 15px;
  }

  .value {
    font-weight: $font-weight-bold;
    font-size: 18px;
  }

  .btn-edit {
    font-size: 14px;
    color: #007bff;
    padding: 5px 15px;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
}
</style>
