<template>
  <app-card-wizard title="Başvuru Sahibi">
    <template #description>
      <p>
        Bu adımda başvuruyu yapan kişi ile ilgilili bilgileri doldurmanız
        gerekmektedir.
      </p>
    </template>
    <app-value-outlet label="E-posta Adresi" :value="email"></app-value-outlet>
    <app-value-outlet
      v-if="applicantType"
      label="Başvuru Sahibi"
      :value="applicantType"
    ></app-value-outlet>
    <div class="row">
      <div class="col-12 col-sm-6">
        <app-form-group
          :validator="validator"
          name="firstName"
          label="Adı"
          help="Bu alanda belirtilen kişi başvuru evraklarında da yer almalıdır"
        >
          <input
            name="firstName"
            class="form-control"
            maxlength="50"
            placeholder="Adınız"
            v-model="firstName"
            :disabled="applicationData.isOnlineUser"
            @keypress="isLetter"
          />
        </app-form-group>
      </div>
      <div class="col-12 col-sm-6">
        <app-form-group :validator="validator" name="lastName" label="Soyadı">
          <input
            name="lastName"
            class="form-control"
            maxlength="50"
            placeholder="Soyadınız"
            v-model="lastName"
            :disabled="applicationData.isOnlineUser"
            @keypress="isLetter"
          />
        </app-form-group>
      </div>
    </div>
    <app-form-group
      :validator="validator"
      name="identityNumber"
      label="T.C. Kimlik No"
      help="Bu alanda belirtilen bilgiler evraklarında da yer almalıdır"
    >
      <input
        name="identityNumber"
        class="form-control"
        placeholder="Başvuruyu yapan kişinin T.C. Kimlik Numarası"
        maxlength="11"
        v-numeric
        v-model="identityNumber"
        @change="updateStoreData"
        :disabled="applicationData.isOnlineUser"
      />
    </app-form-group>
    <app-form-group
      :validator="validator"
      name="gsm"
      label="Cep telefonu"
      help="Bildirmler ve gerekmesi halinde doğrulama kodunun gönderileceği cep telefonu numaranızı girin"
    >
      <p-input-mask
        name="gsm"
        placeholder="Cep telefonu numarası"
        v-model="gsm"
        mask="(999) 999 99 99"
        style="width:100%"
        @change="updateStoreData"
        :disabled="applicationData.isOnlineUser"
      ></p-input-mask>
    </app-form-group>
    <app-wizard-buttons
      :next-button-disabled="isLoading"
      :next-button-is-loading="isLoading"
      :next-button-text="nextButtonText"
      @next-button-click="onSubmit"
      :prev-button-visible="prevButtonVisible"
      :prev-button-disabled="isLoading"
      @prev-button-click="
        redirect('/choose-association/' + applicationData.stateId)
      "
    ></app-wizard-buttons>
  </app-card-wizard>
</template>

<script>
import AppValueOutlet from "../components/ValueOutlet.vue";
import { settings, rules } from "../shared";
import PInputMask from "primevue/inputmask";

export default {
  components: {
    AppValueOutlet,
    PInputMask
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      identityNumber: null,
      gsm: null,
      isLoading: false
    };
  },
  validations: {
    firstName: rules.required,
    lastName: rules.required,
    identityNumber: rules.identityNumber,
    gsm: rules.gsm
  },
  methods: {
    onSubmit() {
      if (!this.applicationData.isOnlineUser) {
        this.validator.$touch();

        if (this.validator.$invalid) {
          this.scrollToError();
          return;
        }
      }

      this.isLoading = true;

      setTimeout(() => {
        const applicant = { ...this.applicant };

        applicant.fullName = this.fullName;
        applicant.identityNumber = this.identityNumber;
        applicant.gsm = this.gsm;
        this.updateApplicationData({ applicant });

        this.isLoading = false;
        this.markStepAsCompleted(this.stepName);

        const path = this.applicationData.fromReview
          ? "/review/"
          : "/company-info/";

        this.goTo(path + this.applicationData.stateId);
      }, 500);
    },
    onEmailChange() {},
    onApplicantTypeChange() {
      console.log("onApplicantTypeChange");
    },
    updateStoreData($event) {
      const applicant = this.applicationData.applicant;
      applicant[$event.target.name] = $event.target.value;
      this.updateApplicationData({ applicant });
    }
  },
  computed: {
    fullName() {
      return this.firstName + " " + this.lastName;
    },
    validator() {
      return this.$v;
    },
    prevButtonVisible() {
      return !this.applicationData.fromReview;
    },
    nextButtonText() {
      return this.applicationData.fromReview ? "Kaydet" : "Devam Et";
    },
    stepName() {
      return "applicant";
    },
    email() {
      return this.applicationData?.email;
    },
    applicant() {
      return this.applicationData?.applicant || {};
    },
    applicantType: function() {
      return settings.applicantTypes[this.applicant.type || "none"]?.title;
    }
  },
  beforeMount() {
    const applicant = (this.applicationData || {}).applicant || {};

    if (applicant.fullName) {
      var aryName = applicant.fullName.split(" ");

      if (aryName.length == 1) {
        this.firstName = aryName[0];
        this.lastName = "";
      } else if (aryName.length == 2) {
        this.firstName = aryName[0];
        this.lastName = aryName[1];
      } else {
        this.firstName = aryName.slice(0, aryName.length - 1).join(" ");
        this.lastName = aryName[aryName.length - 1];
      }
    }

    this.identityNumber = applicant.identityNumber;
    this.gsm = applicant.gsm;

    this.markStepAsCompleted(this.stepName);
  }
};
</script>

<style></style>
